// ===================================================================
// CSS/PCSS Imports
// ===================================================================
import "unfonts.css";
import "/src/css/app.pcss";

// ===================================================================
// Alpine JS
// ===================================================================
import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import collapse from "@alpinejs/collapse";
import Clipboard from "@ryangjchandler/alpine-clipboard"
// import scrollTo from "alpinejs-scroll-to";

Alpine.plugin(focus);
Alpine.plugin(collapse);
Alpine.plugin(Clipboard.configure({
    onCopy: () => {
        alert('Emails have been copied to the clipboard')
    }
}))
// Alpine.plugin(scrollTo);

window.Alpine = Alpine;
Alpine.start();

// ===================================================================
// Smooth Scroll
// ===================================================================
import smoothscroll from "smoothscroll-polyfill";
import SmoothScroll from "smooth-scroll";

smoothscroll.polyfill();
const scroll = SmoothScroll('a[href*="#"]', { header: "#site-header" });

// ===================================================================
// Homepage OBFR Countdown
// ===================================================================
// Set the date we're counting down to
var obfrCountdown = document.getElementById("obfr-countdown");
if (obfrCountdown != null) {
    var countDownDate = new Date(obfrCountdown.dataset.obfrDate).getTime();

    var daysDiv = document.getElementById("days");
    var hoursDiv = document.getElementById("hours");
    var minutesDiv = document.getElementById("minutes");
    var secondsDiv = document.getElementById("seconds");

    // Update the count down every 1 second
    var x = setInterval(function () {
        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Display the result in the element with id="obfr-countdown"
        daysDiv.innerHTML = days;
        hoursDiv.innerHTML = hours;
        minutesDiv.innerHTML = minutes;
        secondsDiv.innerHTML = seconds;

        // If the count down is finished, write some text
        if (distance < 0) {
            clearInterval(x);
            obfrCountdown.innerHTML = "Coming Again Next Year!";
        }
    }, 1000);
}


// ===================================================================
// Swiper core version + navigation modules
// ===================================================================
import 'swiper/css'
import Swiper from 'swiper'
import { Navigation, Pagination, Autoplay }  from 'swiper/modules';

Swiper.use([Navigation, Pagination, Autoplay])

const swiperImageText = document.querySelectorAll(".swiper-imagetext");
const swiperImageTextNext = document.querySelectorAll(".swiper-imagetext-button-next");
const swiperImageTextPrev = document.querySelectorAll(".swiper-imagetext-button-prev");

for (let i = 0; i < swiperImageText.length; i++) {
    swiperImageText[i].classList.add('swiper-imagetext-' + i);
    swiperImageTextNext[i].classList.add('swiper-imagetext-button-next-' + i);
    swiperImageTextPrev[i].classList.add('swiper-imagetext-button-prev-' + i);

    var newSwiperImageText = new Swiper('.swiper-imagetext-' + i, {
        loop: true,
        slidesPerView: 'auto',
        autoplay: false,
        pagination: {
            el: '.swiper-pagination',
            type: 'progressbar',
        },
        navigation: {
            nextEl: '.swiper-imagetext-button-next-' + i,
            prevEl: '.swiper-imagetext-button-prev-' + i,
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 40,
            }
        },
    })
}

const swiperMultiple = document.querySelectorAll(".swiper-multiple");
const swiperNext = document.querySelectorAll(".swiper-multiple-button-next");
const swiperPrev = document.querySelectorAll(".swiper-multiple-button-prev");

for (let i = 0; i < swiperMultiple.length; i++) {
    swiperMultiple[i].classList.add('swiper-multiple-' + i);
    swiperNext[i].classList.add('swiper-multiple-button-next-' + i);
    swiperPrev[i].classList.add('swiper-multiple-button-prev-' + i);

    var newSwiperMultiple = new Swiper('.swiper-multiple-' + i, {
        loop: true,
        slidesPerView: 'auto',
        autoplay: false,
        pagination: {
            el: '.swiper-pagination',
            type: 'progressbar',
        },
        navigation: {
            nextEl: '.swiper-multiple-button-next-' + i,
            prevEl: '.swiper-multiple-button-prev-' + i,
        },
    })
}

const swiperSingle = document.querySelectorAll(".swiper-single");
const swiperSingleNext = document.querySelectorAll(".swiper-single-button-next");
const swiperSinglePrev = document.querySelectorAll(".swiper-single-button-prev");

for (let i = 0; i < swiperSingle.length; i++) {
    swiperSingle[i].classList.add('swiper-single-' + i);
    swiperSingleNext[i].classList.add('swiper-single-button-next-' + i);
    swiperSinglePrev[i].classList.add('swiper-single-button-prev-' + i);

    var newSwiperSingle = new Swiper('.swiper-single-' + i, {
        slidesPerView: 1,
        autoplay: false,
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
        },
        navigation: {
            nextEl: '.swiper-single-button-next-' + i,
            prevEl: '.swiper-single-button-prev-' + i,
        },
    })
}

var simpleSwiperMultiple = new Swiper('.swiper-workshop', {
    slidesPerView: 'auto',
    autoplay: false,
    pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        640: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
    },
})

var simpleSwiperNews = new Swiper('.swiper-news', {
    slidesPerView: 'auto',
    autoplay: false,
    pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
    },
    navigation: {
        nextEl: '.swiper-news-button-next',
        prevEl: '.swiper-news-button-prev',
    },
    breakpoints: {
        640: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
    },
})



const obfrSwiperMultiple = document.querySelectorAll(".obfr-swiper-multiple");
const obfrSwiperNext = document.querySelectorAll(".swiper-button-next");
const obfrSwiperPrev = document.querySelectorAll(".swiper-button-prev");

for (let i = 0; i < obfrSwiperMultiple.length; i++) {
    obfrSwiperMultiple[i].classList.add('obfr-slider-multiple-' + i);
    obfrSwiperNext[i].classList.add('swiper-button-next-' + i);
    obfrSwiperPrev[i].classList.add('swiper-button-prev-' + i);

    var swiper = new Swiper('.obfr-slider-multiple-' + i, {
        loop: true,
        slidesPerView: 'auto',
        autoplay: false,
        navigation: {
            nextEl: '.swiper-button-next-' + i,
            prevEl: '.swiper-button-prev-' + i,
        },
        breakpoints: {
            640: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
        },
    });
}

const obfrSwiperTestimonials = document.querySelectorAll(".obfr-swiper-testimonials");
const obfrSwiperTestimonialsNext = document.querySelectorAll(".swiper-testimonials-button-next");
const obfrSwiperTestimonialsPrev = document.querySelectorAll(".swiper-testimonials-button-prev");

for (let i = 0; i < obfrSwiperTestimonials.length; i++) {
    obfrSwiperTestimonials[i].classList.add('obfr-swiper-testimonials-' + i);
    obfrSwiperTestimonialsNext[i].classList.add('swiper-testimonials-button-next-' + i);
    obfrSwiperTestimonialsPrev[i].classList.add('swiper-testimonials-button-prev-' + i);

    var swiperTestimonials = new Swiper('.obfr-swiper-testimonials-' + i, {
        loop: true,
        slidesPerView: 'auto',
        autoplay: false,
        navigation: {
            nextEl: '.swiper-testimonials-button-next-' + i,
            prevEl: '.swiper-testimonials-button-prev-' + i,
        },
        breakpoints: {
            640: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
        },
    });
}

// ===================================================================
// Light Gallery basic setup
// ===================================================================
// Must be explicite on these imports
import lightGallery from "lightgallery";
import lgVideo from "lightgallery/plugins/video/lg-video.min.js";
import lgFullscreen from "lightgallery/plugins/fullscreen/lg-fullscreen.min.js";

const lightGalleryLicense = "E54AECF9-4ABE49E6-9F73A50C-68BB6298";

const lightGalleries = document.getElementsByClassName("lightgallery");
for (let i = 0; i < lightGalleries.length; i++) {
    lightGallery(lightGalleries[i], {
        licenseKey: lightGalleryLicense,
        plugins: [lgFullscreen],
        download: false,
    });
}

const lightGalleryVids = document.getElementsByClassName("lightgallery-videos");
for (let i = 0; i < lightGalleryVids.length; i++) {
    lightGallery(lightGalleryVids[i], {
        licenseKey: lightGalleryLicense,
        plugins: [lgVideo, lgFullscreen],
        videojs: true,
        download: false,
        autoplayFirstVideo: true,
    });
}

// ===================================================================
// Vite HMR - Dev Only
// ===================================================================
// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}
